body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  border: 0;
}

.day4-block[data-name = "container-qC4_gdJ90"] {
  height: calc(100% - 48px);
}

.day4-block[data-name = "graphiQL-KTiED95NWY"] .resultWrap{
  width: 100%;
  overflow: auto;
}

.day4-block[data-name = "container-KqR5z9ju3-"] {
  .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
    color: #ffffff;
  }
  .ant-menu-submenu-arrow, .ant-menu-submenu-expand-icon {
    color: #ffffff;
  }
}