.container {
  .ant-table-container .ant-table-tbody {
    // tr:nth-child(even) td {
    //   border: solid 1px rgb(247, 248, 250);
    // }
    // tr:nth-child(odd) td {
    //   border: solid 1px #fff;
    // }
    tr td:hover {
      border: solid 1px #24b0e1 !important;
      cursor: pointer;
    }
    margin-bottom: 5px;
  }

  .bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
}


.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  border: transparent;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  //background: #fff;
  //border-top: 1px solid #e9e9e9;
}
.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  //padding: 48px;
  //overflow: hidden;
  //text-align: center;
  //background: #fafafa;
  //border: 1px solid #ebedf0;
  //border-radius: 2px;
}
