.footer {
  margin: 0 -16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  button {
    margin-top: 10px;
    &:last-child {
      margin-right: 16px;
      margin-left: 10px;
    }
  }
}

.content {
  max-height: 600px;
  width: auto;
  overflow-y: auto;
  margin: 0 -16px;
  form {
    margin: 0 16px;
  }
}
