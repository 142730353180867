
.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  border: transparent;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  //background: #fff;
  //border-top: 1px solid #e9e9e9;
}
.site-drawer-render-in-current-wrapper {
  position: relative;
  //height: 200px;
  //padding: 48px;
  overflow: hidden;
  //text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}